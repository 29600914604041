<template>
  <div class="p-3">
    <appCheckProfessionalMembership
      :projectId="projectId"
      :productId="productId"
      :userId="userId"
    ></appCheckProfessionalMembership>
  </div>
</template>
<script>
import appCheckProfessionalMembership from "../../components/checkProfessionalMembership/checkProfessionalMembership.vue";
import * as encodeDecodeService from "../../services/encodeDecode.service";
export default {
  name: "CheckProfessionalMembership",

  components: {
    appCheckProfessionalMembership,
  },

  data() {
    return {
      projectId: encodeDecodeService.cyDecrypt(this.$route.params.projectId),
      userId: encodeDecodeService.cyDecrypt(this.$route.params.userId),
      productId: encodeDecodeService.cyDecrypt(this.$route.params.checkId),
    };
  },
};
</script>